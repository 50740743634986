const pinkWave = (p) => {

    let w = 30
    let ma
    let zOff = 0
    let maxD
    // let angle = 0

    p.setup = () => {
    p.createCanvas(500, 500, p.WEBGL)
    p.colorMode(p.HSB, 300)
    ma = p.atan(1 / p.sqrt(2))
    }

    p.draw = () => {
    p.background(0, 218.79, 269.4)
    p.translate(0, -200, -50)
    p.rotateX(-ma)
    p.rotateY(-ma)
    p.ortho(-400, 500, -400, 500, -1000, 1000)

    let yOff = 0
    for (let z = 0; z < p.height; z += w) {
        let xOff = 0
        for (let i = w / 2; i < p.width; i += w) {
        p.noiseDetail(4)
        maxD = 400
        let h = p.map(p.noise(xOff, yOff, zOff), 0, 1, 0, maxD)
        let col = p.constrain(h, 0, 255)
        p.stroke(0, 50)
        p.strokeWeight(3)
        p.smooth()
        p.fill(col * 1.5, col / 2, col * 1.5)
        p.push()
        p.translate(i, 0, z)
        p.box(w - 2, h, w - 2)
        p.pop()
        xOff += 0.2
        // angle += 0.0001
        }
        yOff += 0.2
    }
    zOff += 0.005
    }
}

export default pinkWave