import React from "react"
import "../css/global.css"
import { Link } from 'gatsby'

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"

import P5Wrapper from "react-p5-wrapper"
import blueWave from '../sketches/blueWave.js'
import pinkWave from '../sketches/pinkWave.js'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className="my-b-8 text-white text-left text-xl font-semibold font-sans">
      <p className="my-b-4 leading-loose text-white text-5xl font-bold tracking-wider">
        Coder. Developer. Magic Maker...{" "}
      </p>
      <p className="my-4  max-w-3xl">
        My name is Devon Lumsden, and I am a developer based in the wonderful
        Auckland, NZ. <br /> And I make magic. <br /> I do that through code.
        <br /> I'm interested in all aspects of the digital world and love
        delving into the unknown.
      </p>
      <p>What I'm Reading: The Creativity Code: How AI Is Learning to Write, Paint and Think</p>
      <br />
      <p>If you're interested in chatting, be sure to <Link to='/contact'> send through an email</Link>.</p>
      <div style={{ maxWidth: `400px`, marginBottom: `1.45rem` }}>
        {/* <Image /> */}
      </div>
      <p className="text-black text-3xl font-sans m-4"></p>
    </div>
    {typeof window !== 'undefined' ? <div className="flex flex-col lg:flex-row ">
      <P5Wrapper sketch={blueWave} />
      <P5Wrapper sketch={pinkWave} />
      </div>: null}
  </Layout>
)

export default IndexPage
