const blueWave = (p) => {

    let angle = 0
    let w = 24
    // let ma
    let maxD

    p.setup = () => {
      p.createCanvas(500, 500, p.WEBGL)
      p.smooth()
      p.colorMode(p.HSB, 300)
    //   ma = p.atan(1 / p.sqrt(2))
      maxD = p.dist(0, 0, 300, 300)
    }

    p.draw = () => {
      p.background(0, 218.79, 269.4)
      p.stroke(0, 100)
      p.ortho(-500, 500, -500, 500, 0, 1000)
      p.directionalLight(255, 255, 255, 0, 50, -100)
      p.directionalLight(220, 220, 220, 50, 0, 0)
      p.ambientLight(100, 100, 150)
      p.translate(0, 0, -200)
      p.rotateX(-p.PI / 4)
      p.rotateY(-p.PI / 4)
      p.rectMode(p.CENTER)
      for (let z = 0; z < p.height; z += w) {
        for (let x = 0; x < p.width; x += w) {
          p.push()
          let d = p.dist(x, z, p.mouseX, p.mouseY)
          let offset = p.map(d, 0, maxD, -p.PI, p.PI)
          let a = angle + offset
          let h = p.map(p.sin(a), -1, 1, 50, 400)
          p.translate(x - p.width / 2, 0, z - p.height / 2)
          p.fill(100, 100, 200)
          p.ambientMaterial(50, 200, 100)
          p.shininess(5)
          p.specularMaterial(175, 250, 250)
          p.box(w - 2, h, w - 2)
          p.pop()
        }
      }
      angle += 0.05
    }
}

export default blueWave